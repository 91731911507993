.rating-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.radio-label {
  position: relative;
  margin: 0 10px;
  cursor: pointer;
  font-size: 18px; /* Adjust font size */
  color: black; /* Change label color to black */
  display: flex;
  align-items: center;
}

.radio-input {
  opacity: 0; /* Hide the default radio button */
  position: absolute;
  cursor: pointer;
}

.custom-radio {
  width: 20px; /* Smaller size */
  height: 20px; /* Smaller size */
  border: 2px solid black; /* Black border */
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  transition: background-color 0.3s;
}

.radio-input:checked + .custom-radio {
  background-color: black; /* Black background when selected */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
