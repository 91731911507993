body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #ffe6e6; /* Light red background */
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #b30000; /* Darker red for headings */
  margin-bottom: 20px;
}

input {
  border: 2px solid #b30000;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 300px;
  margin-bottom: 20px;
}

input::placeholder {
  color: #b30000; /* Placeholder color */
  opacity: 0.7; /* Slightly faded */
}

button {
  background-color: #b30000; /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #900000; /* Darker red on hover */
}

img {
  margin-top: 30px;
  width: 300px;
  height: auto;
  border-radius: 10px; /* Rounded corners for the image */
}

p {
  color: #b30000; /* Red text for output */
  font-size: 20px;
  margin-top: 10px;
}

input[type="radio"] {
  margin-right: 5px;
}
